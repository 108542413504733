import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const RefugeesAndImmigrants = () => (
  <Layout sermonSrc="https://player.vimeo.com/video/389040257">
    <SEO title="Refugees, Immigrants, and the Southern Border - Radical Lectures" />
  </Layout>
)

export default RefugeesAndImmigrants
